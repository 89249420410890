import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="xdelivery-map"
export default class extends Controller {
  static targets = ['storeName', 'storeNum', 'storeAddress'];

  connect() {
    window.addEventListener('message', (event) => {
      if (event.data.id && event.data.name && event.data.address) {
        this.storeNumTarget.value = event.data.id;
        this.storeNameTarget.value = event.data.name;
        this.storeAddressTarget.value = event.data.address;
        $('#storeData').text(event.data.id + ' ' + event.data.name + '(' + event.data.address + ')')
      }
    });
  }

  submit(event) {
    event.preventDefault(); 

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://map.xdelivery.io/fami';
    form.target = '_blank';
    form.rel = 'opener';

    const callbackUrlInput = document.createElement('input');
    callbackUrlInput.type = 'hidden';
    callbackUrlInput.name = 'callback_url';
    callbackUrlInput.value = this.data.get('callbackUrlValue');

    form.appendChild(callbackUrlInput);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
}