import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comment-link"
export default class extends Controller {

  connect() {
    const hash = window.location.hash;
    if (hash) {
      const target = document.querySelector(hash);
      if (target) {
        const firstDiv = target.querySelector("div");
        firstDiv.classList.add("bg-warning-light");
        this.timeout = setTimeout(
          () => firstDiv.classList.remove("bg-warning-light"),
          3000
        );
      }
    }
  }

  disconnect() {
    clearTimeout(this.timeout);
  }
}
